import moment from 'moment'
import { groupBy, flatMap } from 'lodash'
import i18n from '@Shared/i18n'

const settings = {
  DateFormatForBackend: 'YYYY-MM-DD',
  DateFormatForCalendar: 'YYYY-MM-DD',
  DateFormatForFrontend: 'DD/MM/YYYY',
  DateFormatForFrontendUS: 'MM/DD/YYYY',
  DateFormatForFrontendMonthAsWord: 'DD MMMM YYYY',
  DateFormatForFrontendMonthAsWordUS: 'MMMM DD YYYY',
}

export const GLOBAL_FILTERS = {
  SELF_ASSIGNED: i18n.t(
    'timesheetUI.HeaderSearchTimesheet.FilterOptions.self_assigned',
    window.CULTURE
  ),
  MY_AGENCY: i18n.t(
    'timesheetUI.HeaderSearchTimesheet.FilterOptions.my_agency',
    window.CULTURE
  ),
  OTHER_AGENCIES: i18n.t(
    'timesheetUI.HeaderSearchTimesheet.FilterOptions.other_agencies',
    window.CULTURE
  ),
  ASSIGNED_JOB: i18n.t(
    'timesheetUI.HeaderSearchTimesheet.FilterOptions.assigned_job',
    window.CULTURE
  ),
  FAVOURITES: i18n.t(
    'timesheetUI.HeaderSearchTimesheet.FilterOptions.favourites',
    window.CULTURE
  ),
}

const isUsAgency = () => {
  if (window.AltairUserContract && window.AltairUserContract.AltairAgencyRule) {
    return window.AltairUserContract.AltairAgencyRule.IsUsAgency
  }

  if (
    window.AltairUserContract &&
    window.AltairUserContract.agencyCountryCode
  ) {
    return window.AltairUserContract.agencyCountryCode === 'US'
  }

  return false
}

export const formatDateFrontend = (value, isUS) => {
  if (
    value === '31/12/9999' ||
    value === '12/31/9999' ||
    value === '01/01/1970'
  ) {
    return value
  }

  let dateFormat =
    isUsAgency() || isUS
      ? settings.DateFormatForFrontendUS
      : settings.DateFormatForFrontend
  let dateNoTimeOfDay

  if (value && value._isValid) {
    dateNoTimeOfDay = value
  } else {
    dateNoTimeOfDay = value
      ? value.includes('T')
        ? value.split('T')[0]
        : value
      : value
  }

  let formattedDate = moment(dateNoTimeOfDay).format(dateFormat)

  return formattedDate === 'Invalid date' ? '' : formattedDate
}

export const formatDateFrontendKnownFormat = (value, isUS) => {
  let dateFormat =
    isUsAgency() || isUS
      ? settings.DateFormatForFrontendUS
      : settings.DateFormatForFrontend
  let formattedDate = moment(value).format(dateFormat)
  let formattedDateKnownFormat = moment(value, dateFormat).format(dateFormat)
  return formattedDateKnownFormat === 'Invalid date'
    ? formattedDate
    : formattedDateKnownFormat
}

export const formatDateFrontendUTC = (value, isUS) => {
  let dateFormat =
    isUsAgency() || isUS
      ? settings.DateFormatForFrontendUS
      : settings.DateFormatForFrontend
  let formattedDate = moment(value).utc().format(dateFormat)
  return formattedDate === 'Invalid date' ? '' : formattedDate
}

export const formatDateBackend = (value) => {
  return moment(value).format(settings.DateFormatForBackend)
}

export const formatDateBackendUS = (value) => {
  return moment(value, 'MM/DD/YYYY').format(settings.DateFormatForBackend)
}

export const formatDateBackendEU = (value) => {
  return moment(value, 'DD/MM/YYYY').format(settings.DateFormatForBackend)
}

export const formatDateBackendKnownFormat = (value) => {
  let dateFormat = isUsAgency()
    ? settings.DateFormatForFrontendUS
    : settings.DateFormatForFrontend
  let formattedDate = moment(value).format(settings.DateFormatForBackend)
  let formattedDateKnownFormat = moment(value, dateFormat).format(
    settings.DateFormatForBackend
  )
  return formattedDateKnownFormat === 'Invalid date'
    ? formattedDate
    : formattedDateKnownFormat
}

export const dateFormatForCalendar = (value) => {
  return moment(value).utc().format(settings.DateFormatForCalendar)
}

export const dateNow = () => {
  return moment().utc().format(settings.DateFormatForFrontend)
}

export const dateNowNoFormat = () => {
  return moment()
}

export const formatDateMonthAsWord = (value) => {
  if (value === '' || value === null) {
    return 'N/A'
  }

  const locale = window.CULTURE === 'fr-FR' ? 'fr' : 'en'
  let dateFormat =
    window.AltairUserContract && window.AltairUserContract.isUsAgency
      ? settings.DateFormatForFrontendMonthAsWordUS
      : settings.DateFormatForFrontendMonthAsWord
  let formattedDate = moment(value.slice(0, 10))
    .locale(locale)
    .format(dateFormat)

  return formattedDate === 'Invalid date' ? '' : formattedDate
}

export const percisionRound = (x, precision) => {
  /*
        Convert decimal places to a string and count length becauces toPercision requires the total
        of numbers to return, so 12.2 is toPercision 3 but if the percion to round up by is 0.25 we require
        2 decimal places i.e. 12.25.

        What we do is count the number of characters to reflect the decimal places of the percision value 0.1 = 3, 0.25 = 4.
    */
  let isNegative = false
  if (x < 0) {
    isNegative = true
    x = Math.abs(x)
  }
  let decimalPlaces = `${precision}`.length
  let y = +x + (precision === undefined ? 0.5 : precision / 2)
  let roundedInt = y - (y % (precision === undefined ? 1 : +precision))
  let clearFloatingPoint =
    Number.parseFloat(roundedInt).toPrecision(decimalPlaces)

  if (precision === 1) {
    clearFloatingPoint = Number.parseFloat(roundedInt)
  }

  if (isNegative) {
    return parseFloat(clearFloatingPoint) * -1
  }

  return parseFloat(clearFloatingPoint)
}

export const getParam = (paramName) => {
  let url = new URL(window.location.href).searchParams

  return url.get(paramName)
}

export const getParamTop = (paramName) => {
  let url = new URL(window.top.location.href).searchParams

  return url.get(paramName)
}

export const currency = (value, currency) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(
    value
  )
}

export const getBillableIndicatorCode = (biCode) => {
  const options = {
    'Non-billable': 'N',
    'Covered by fee': 'F',
    Billable: 'B',
    Empty: 'E',
  }

  return biCode?.value || options[biCode]
}

export const getBillableIndicatorName = (biName) => {
  if (!biName) {
    return biName
  }

  const options = {
    N: 'Non-billable',
    F: 'Covered by fee',
    B: 'Billable',
    E: 'Empty',
  }

  return biName.length > 1 ? biName : options[biName]
}

export const arrowKeyHandler = ($e) => {
  if ($e.key === 'ArrowDown' || $e.key === 'ArrowUp') {
    document.querySelector(`#${$e.target.id}`).click()
  }
}

export const handleArrowKeys = (context, $e, ids) => {
  let refName = null

  for (const id of ids) {
    if ($e.target && $e.target.id === id) {
      refName = id
      break
    }
  }

  if (!refName) return

  if ($e.key === 'ArrowDown' || $e.key === 'ArrowUp') {
    const menuItems =
      context.$refs[refName].$refs.menu.$refs.content.querySelectorAll(
        '.v-list-item'
      )
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].classList.contains('v-list-item--highlighted')) {
        context.liveRegionText = menuItems[i].innerText
        break
      }
    }
  }
}

export const groupLocations = (locationArray) => {
  const grouped = groupBy(locationArray, (location) => location.country)

  if ('undefined' in grouped || 'null' in grouped) {
    return locationArray
  }

  return flatMap(
    Object.keys(grouped).map((a) => {
      return [
        {
          header: a,
        },
        ...grouped[a],
      ]
    })
  )
}

export const filterRestrictedJobsNotInRange = (
  jobOrTimesheetList,
  calendar
) => {
  return jobOrTimesheetList.filter((a) => {
    if (a.allocationStartDate && a.allocationEndDate) {
      const calendarStartDate = calendar[0]?.date?.split('T')[0]
      const calendarEndDate = calendar.slice(-1)[0]?.date?.split('T')[0]
      const allocationStartDate = a.allocationStartDate.split('T')[0]
      const allocationEndDate = a.allocationEndDate.split('T')[0]

      const inStartRange =
        moment(allocationStartDate).isSameOrBefore(calendarEndDate)
      const inEndRange =
        moment(allocationEndDate).isSameOrAfter(calendarStartDate)

      if (inStartRange && inEndRange) {
        return true
      } else {
        return jobOrTimesheetList.some((job) => {
          return job.durationValue > 0
        })
      }
    }
    return true
  })
}

export const checkRestriction = (
  day,
  timesheetJob,
  durationValue,
  checkContract = true
) => {
  const result = {
    disabled: false,
    errorType: null,
    startDate: null,
    endDate: null,
  }

  if (checkContract) {
    if (day.isRequiredByUserContract === false) {
      result.disabled = true
      result.errorType = 'contract-error'
      return result
    }
  }

  const jobStartDate = timesheetJob.allocationStartDate?.split('T')[0]
  const jobEndDate = timesheetJob.allocationEndDate?.split('T')[0]
  const date = day.date?.split('T')[0]

  if (jobStartDate && jobEndDate && date) {
    const isTimesheetBetweenAllocationDates = moment(date).isBetween(
      jobStartDate,
      jobEndDate,
      undefined,
      '[]'
    )

    if (!isTimesheetBetweenAllocationDates && durationValue > 0) {
      result.disabled = true
      result.startDate = jobStartDate
      result.endDate = jobEndDate

      return result
    }

    if (isTimesheetBetweenAllocationDates) {
      return result
    }

    result.disabled = true
    result.startDate = jobStartDate
    result.endDate = jobEndDate

    if (parseFloat(durationValue) > 0) {
      result.errorType = 'time-entry-post-restriction-error'
    } else {
      result.errorType = 'time-entry-restriction-error'
    }

    return result
  }

  return result
}

export const capitalizeWords = (inputText) => {
  if (!inputText) {
    return ''
  }

  const words = inputText.split(' ')
  const capitalizedWords = words.map((word) => {
    if (word.length === 0) {
      return word
    }
    const firstChar = word.charAt(0).toUpperCase()
    const restOfString = word.slice(1).toLowerCase()
    return `${firstChar}${restOfString}`
  })

  return capitalizedWords.join(' ')
}

export const getDaysOfWeekBetweenDates = (sDate, eDate) => {
  const daysOfWeek = []

  for (var current = sDate; current <= eDate; current.add(1, 'd')) {
    if (moment(current).day() !== 6 && moment(current).day() !== 0) {
      daysOfWeek.push(current.format('YYYY-MM-DD'))
    }
  }
  return daysOfWeek
}

export const filterJobsByVisibility = (
  jobs,
  selectedDropdownFilterValue,
  stateDropdownFilterValue
) => {
  const filteredtimesheetJobs = jobs.filter((job) => {
    if (
      selectedDropdownFilterValue === stateDropdownFilterValue.showAll ||
      // eslint-disable-next-line no-prototype-builtins
      !job.hasOwnProperty('isJobVisible')
    ) {
      return true
    }
    if (
      selectedDropdownFilterValue === stateDropdownFilterValue.visible &&
      job.isJobVisible
    ) {
      return true
    }
    if (
      selectedDropdownFilterValue === stateDropdownFilterValue.hidden &&
      !job.isJobVisible
    ) {
      return true
    }
  })
  return filteredtimesheetJobs
}

export const gotoClarizenProject = (id) => {
  const domain = process.env.VUE_APP_CLARIZEN_URL
  const url = `${domain}/Clarizen/Project/${id}`
  window.open(url, '_blank').focus()
}

export const getTimesheetsDuplicate = (
  timesheets,
  clarizenProjectId,
  date,
  returnTimesheets,
  isClarizenTask,
  clarizenTaskExternalId,
  task,
  isHistoricalJob = false
) => {
  const found = timesheets.filter((a) => {
    let isTimesheetTaskSameAsJobTask = true

    if (!isHistoricalJob) {
      isTimesheetTaskSameAsJobTask = a.task === task || (!task && !a.task)
    }

    return (
      a.clarizenProjectId ===
        (clarizenProjectId && clarizenProjectId.replace('/Project/', '')) &&
      a.clarizenTaskExternalId === clarizenTaskExternalId &&
      (!date ||
        moment(a.reportedDate.split('T')[0]).isSame(date.split('T')[0])) &&
      a.isClarizenTask === isClarizenTask &&
      isTimesheetTaskSameAsJobTask
    )
  })

  return returnTimesheets
    ? found
    : found
        .map((a) =>
          parseFloat(
            a.isPercentageEntry ? a.durationPercentageValue : a.durationValue
          )
        )
        .reduce((a, b) => percisionRound(parseFloat(a + b), 0.01), 0) || 0
}

export const getTimesheets = (
  timesheets = [],
  userJobId = false,
  date = false,
  key = false
) => {
  if (date) {
    const found = timesheets.filter(
      (a) =>
        (userJobId ? a.userJobId === userJobId : true) &&
        moment(a.reportedDate.split('T')[0]).isSame(date.split('T')[0])
    )

    if (found) {
      if (key === 'durationValue') {
        return (
          found
            .map((a) => {
              let newKey = key

              if (a.isPercentageEntry) {
                newKey = 'durationPercentageValue'
              }

              return parseFloat(a[newKey])
            })
            .reduce((a, b) => percisionRound(parseFloat(a + b), 0.01), 0) ||
          null
        )
      }

      return found
    }

    return null
  }

  return timesheets.filter((a) => a.userJobId === userJobId)
}

export const isTimesheetRejected = (_timesheets) => {
  if (_timesheets)
    return _timesheets.filter(
      (timesheet) => timesheet.timesheetStatus.toLowerCase() === 'rejected'
    )
  return []
}

export const isJobHidden = (hoursTotalPerJob, job, index) => {
  return (
    // eslint-disable-next-line no-prototype-builtins
    job.hasOwnProperty('isJobVisible') &&
    !job.isJobVisible &&
    !hoursTotalPerJob[index].percent &&
    !hoursTotalPerJob[index].hours
  )
}

export const formatDayCellTooltipText = (timesheetStatuses) => {
  if (timesheetStatuses.length === 0) {
    return ''
  } else if (timesheetStatuses.length === 1) {
    return timesheetStatuses[0]
  } else if (timesheetStatuses.length === 2) {
    return timesheetStatuses.join(' and ')
  } else {
    const lastWord = timesheetStatuses.pop()
    return timesheetStatuses.join(', ') + ', and ' + lastWord
  }
}
